<template>

  <div class="row" ref="galleryForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="form-group">
              <label>Gallery</label>
              <el-tooltip placement="right"  >
                <div slot="content">
                  (738  * 1000 )
                </div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <multiple-file-uploader-file-manager
                :preview-width="'200px'"
                :preview-height="'200px'"
                :filesNumber="1000"
                :accepted-files="imagesExtensions"
                v-model="formData.gallery"
              >
              </multiple-file-uploader-file-manager>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/forums/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>
  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import GeneralDataTable from "@/components/GeneralDataTable";
import vSelect from "vue-select";
import PrimeUploader from "../../components/PrimeUploader";
import MultipleFileUploaderFileManager from "../../components/FileManager/MultipleFileUploaderFileManager";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    MultipleFileUploaderFileManager,
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    'editor': Editor,
    vSelect,
  },
  data() {
    return {
      loader: {},
      id: undefined,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        gallery: [],
        forum_id: '',
      },
      imagesExtensions: this.getImagesExtensions(),
    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.galleryForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.getGallery();
    } else {
      this.entityNotFoundError = true;
      this.$notify({
        message: "Forum Not Found",
        timeout: 2000,
        type: 'danger'
      });
    }
  },
  methods: {
    getGallery() {
      let data = {
        id: this.id
      };
      this.axios.post("/forums/get-gallery", data).then((response) => {
        this.formData.gallery = response.data.gallery;
        this.formData.forum_id = this.id;
        this.formTitle = response.data.forum ?  'Mange ' + response.data.forum.title + ' Gallery' : "";
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Forum Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = {...this.formData};

      request = this.axios.post('forums/manage-gallery', formData);
      successMessage = "News Updated Successfully";

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/forums/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },

  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0;
  padding-bottom: 3px;

}

.extra-fields > h4 {
  padding: 20px;
}

.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF;
  background-color: white;
  border-radius: 2px;
  color: #23CCEF;
}

.style-chooser .vs__deselect {
  background-color: transparent;
  color: #23CCEF;
  opacity: 1;
  font-size: 12px;
}

.style-chooser .vs__dropdown-option--selected {
  color: #23CCEF;
}

.style-chooser .prev-next {
  margin-top: 15px;
}

.style-chooser .prev-next button {
  margin-right: 15px;
  margin-left: 10px;
}
</style>
